import { FirebaseApp } from 'firebase/app';
import {Database} from "firebase/database";

import React from 'react';


interface FirebaseContextModel {
    firebaseApp: FirebaseApp
    database: Database
}

export const FirebaseContext = React.createContext<FirebaseContextModel>({} as any);
